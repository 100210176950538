import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import styled from 'styled-components';
import { Column, Heading1, Spacer, Heading5, LargeText } from '../../components';
import { StoresApi } from '../../api';
import { currentStoreSlice, storesSlice } from '../../store/slices';
import { colors, spacingPx, boxShadow } from '../../theme';
import { Store } from '../../types';
import { useNavigate } from 'react-router';

interface Props {
	isInitialStoreSelection?: boolean;
}

const Stores = ({ isInitialStoreSelection }: Props) => {
	const dispatch = useAppDispatch();

	const stores = useAppSelector(state => state.stores);

	useEffect(() => {
		StoresApi.get().then(res => dispatch(storesSlice.actions.setStores(res)));
	}, [dispatch]);

	return (
		<ScreenWrapper fullScreen={isInitialStoreSelection}>
			<Spacer height={12} />
			{!isInitialStoreSelection && <Heading1>Cambiar de sucursal</Heading1>}
			{isInitialStoreSelection && (
				<>
					<Heading1>Selecciona tu sucursal</Heading1>
					<Heading1>más cercana</Heading1>
				</>
			)}

			{stores.map(s =>
				isInitialStoreSelection ? (
					<StoreCard store={s} key={s.id} />
				) : (
					<NavigatableStoreCard store={s} key={s.id} />
				)
			)}
			<Spacer height={10} />
		</ScreenWrapper>
	);
};

interface StoreCardProps {
	store: Store;
}

const StoreCard = ({ store }: StoreCardProps) => {
	const dispatch = useAppDispatch();

	const currentStore = useAppSelector(state => state.currentStore);

	const selected = currentStore?.id === store.id;
	const textColor = selected ? colors.white : colors.text;

	return (
		<Card
			onClick={() => {
				dispatch(currentStoreSlice.actions.setCurrentStore(store));
			}}
			selected={selected}>
			<Heading5 color={textColor}>{store.name}</Heading5>
			<Spacer height={1} />
			<LargeText color={textColor}>{store.address}</LargeText>
		</Card>
	);
};

const NavigatableStoreCard = ({ store }: StoreCardProps) => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const currentStore = useAppSelector(state => state.currentStore);

	const selected = currentStore?.id === store.id;
	const textColor = selected ? colors.white : colors.text;

	return (
		<Card
			onClick={() => {
				dispatch(currentStoreSlice.actions.setCurrentStore(store));
				navigate('/products');
			}}
			selected={selected}>
			<Heading5 color={textColor}>{store.name}</Heading5>
			<Spacer height={1} />
			<LargeText color={textColor}>{store.address}</LargeText>
		</Card>
	);
};

const ScreenWrapper = styled(Column)<{ fullScreen?: boolean }>`
	background-color: ${colors.body};
	align-items: center;
	min-height: ${({ fullScreen }) =>
		fullScreen ? `100%` : `calc(100vh - ${spacingPx(55)})`};
`;

const Card = styled(Column)<{ selected: boolean }>`
	background-color: ${({ selected }) =>
		selected ? colors.accentSecondary : colors.white};
	border-radius: ${spacingPx(4)};
	box-shadow: ${boxShadow.default};
	height: ${spacingPx(20)};
	width: ${spacingPx(84)};
	margin-top: ${spacingPx(10)};
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;

export default Stores;
