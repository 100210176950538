import { initializeApp } from 'firebase/app';

const firebaseConfig = {
	apiKey: 'AIzaSyDofFkrsz5Du3SeQJrznESv7Mufauc0CoI',
	authDomain: 'online-store-santa-ines.firebaseapp.com',
	projectId: 'online-store-santa-ines',
	storageBucket: 'online-store-santa-ines.appspot.com',
	messagingSenderId: '713712600357',
	appId: '1:713712600357:web:b2f73a6640fde1329d06cc',
	measurementId: 'G-F933E0QMPK',
};

initializeApp(firebaseConfig);
