import React from 'react';
import styled from 'styled-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { Column, SocialIcons, BodyText, SpacedRow } from '../../components';
import { spacingPx, colors, mediaSize } from '../../theme';

import footerIllustration from '../../assets/illustration/footer.png';

const BottomBar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	return (
		<Container>
			<Divider />
			<Content>
				<SocialIcons />
				{!isMobile && (
					<Column>
						<CopyrightText bold>© 2022 Santa Ines - Fábrica de pastas.</CopyrightText>
						<CopyrightText bold>Todos los derechos reservados.</CopyrightText>
					</Column>
				)}
			</Content>
			{isMobile && <Illustration src={footerIllustration} />}
		</Container>
	);
};

const Container = styled(Column)`
	height: ${spacingPx(30)};
	padding: 0 ${spacingPx(12)};

	@media ${mediaSize.mobile} {
		height: ${spacingPx(60)};
		padding: 0 ${spacingPx(6)};
	}
`;

const Content = styled(SpacedRow)`
	align-items: center;
	height: ${spacingPx(35)};
`;

const Divider = styled.div`
	background-color: ${colors.text};
	height: 1px;
	width: 100%;
`;

const CopyrightText = styled(BodyText)`
	text-align: right;
`;

const Illustration = styled.img`
	width: ${spacingPx(90)};
	height: ${spacingPx(45)};
	align-self: center;
	object-fit: contain;
`;

export default BottomBar;
