import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery, useTheme, Drawer } from '@mui/material';
import {
	Row,
	CallButton,
	Column,
	SpacedColumn,
	Spacer,
	SocialIcons,
} from '../../components';
import { spacingPx, colors } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/pro-solid-svg-icons';

import logo from '../../assets/logo-small.png';
import { useAppSelector } from '../../hooks/redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import _ from 'lodash';

import drawerLogoSVG from '../../assets/svg/logo-drawer.svg';
import drawerIllustration from '../../assets/illustration/drawer.png';

import { faTimes } from '@fortawesome/pro-light-svg-icons';

const TopBar = () => {
	const location = useLocation();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const currentStore = useAppSelector(state => state.currentStore);

	const [drawerIsOpen, setDrawerIsOpen] = useState(false);

	const isSelected = (routes: string[]) => {
		for (const route of routes) {
			if (route === '/' && location.pathname === '/') return true;
			if (route !== '/' && _.startsWith(location.pathname, route)) return true;
		}

		return false;
	};

	useEffect(() => {
		setDrawerIsOpen(false);
	}, [location.pathname]);

	if (isMobile) {
		return (
			<MobileContainer>
				<FontAwesomeIcon
					icon={faBars}
					color={colors.darkGray}
					onClick={() => setDrawerIsOpen(true)}
				/>
				<CallButton />
				<Drawer anchor="left" open={drawerIsOpen} onClose={() => setDrawerIsOpen(false)}>
					<DrawerContainer>
						<Column>
							<CloseIconContainer onClick={() => setDrawerIsOpen(false)}>
								<FontAwesomeIcon icon={faTimes} color={colors.text} />
							</CloseIconContainer>
							<DrawerLogo src={drawerLogoSVG} />
							<Spacer height={16} />
							<DrawerLink
								to="/products"
								selected={isSelected(['/', '/products', '/combos'])}>
								Productos
							</DrawerLink>
							<DrawerLink to="/my-store" selected={isSelected(['/my-store'])}>
								Infomación del local
							</DrawerLink>
							{currentStore?.delivery_enabled && (
								<DrawerLink to="/delivery" selected={isSelected(['/delivery'])}>
									Delivery
								</DrawerLink>
							)}
							<DrawerLink to="/stores" selected={isSelected(['/stores'])}>
								Sucursales
							</DrawerLink>
						</Column>
						<Column>
							<DrawerSeparator />
							<Spacer height={6} />
							<SocialIcons />
							<DrawerIllustration src={drawerIllustration} />
						</Column>
					</DrawerContainer>
				</Drawer>
			</MobileContainer>
		);
	}

	return (
		<Container>
			<RouterLink to="/products">
				<Logo src={logo} alt="logo" />
			</RouterLink>
			<Link to="/products" selected={isSelected(['/', '/products', '/combos'])}>
				Productos
			</Link>
			<Link to="/my-store" selected={isSelected(['/my-store'])}>
				Infomación del local
			</Link>
			{currentStore?.delivery_enabled && (
				<Link to="/delivery" selected={isSelected(['/delivery'])}>
					Delivery
				</Link>
			)}
			<Link to="/stores" selected={isSelected(['/stores'])}>
				Sucursales
			</Link>
		</Container>
	);
};

const Container = styled(Row)`
	position: sticky;
	top: 0;
	z-index: 2;
	background-color: ${colors.white};
	width: 100%;
	height: ${spacingPx(25)};
	padding: 0 ${spacingPx(14)};
`;

const MobileContainer = styled(Container)`
	background-color: ${colors.body};
	justify-content: space-between;
	padding: 0 ${spacingPx(10)};
	width: calc(100% - ${spacingPx(20)});
`;

const Logo = styled.img`
	height: ${spacingPx(12.5)};
	width: ${spacingPx(20)};
	object-fit: contain;
`;

const Link = styled(RouterLink)<{ selected?: boolean }>`
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
	padding-left: ${spacingPx(20)};
	text-decoration: none;
	color: ${({ selected }) => (selected ? colors.accentPrimary : colors.text)};
`;

const DrawerLink = styled(Link)`
	padding-left: 0;
	margin-bottom: ${spacingPx(10)};
`;

const DrawerContainer = styled(SpacedColumn)`
	flex: 1;
	width: 60vw;
	padding: ${spacingPx(15)} ${spacingPx(10)} 0 ${spacingPx(10)};
	overflow-x: hidden;
`;

const DrawerLogo = styled.img`
	width: ${spacingPx(45)};
	align-self: center;
`;

const DrawerIllustration = styled.img`
	width: ${spacingPx(60)};
	align-self: center;
`;

const DrawerSeparator = styled.div`
	height: 1px;
	width: 100%;
	background-color: ${colors.accentSecondary};
`;

const CloseIconContainer = styled.div`
	align-self: flex-end;
	font-size: ${spacingPx(8)};
	cursor: pointer;
	height: ${spacingPx(8)};
	margin: 0 -${spacingPx(4)} -${spacingPx(12)} 0;
	z-index: 1;
`;

export default TopBar;
