import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme, Dialog } from '@mui/material';
import { useAppSelector } from '../../hooks/redux';
import styled from 'styled-components';

import { spacingPx, colors, mediaSize } from '../../theme';
import placeOrderIllustration from '../../assets/illustration/place-order.png';
import {
	Column,
	Spacer,
	SpacedRow,
	BodyText,
	Button,
	CenteredColumn,
} from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const PlaceOrder = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const store = useAppSelector(state => state.currentStore);

	const onClose = () => {
		navigate(location.pathname.replace('/place-order', ''));
	};

	return (
		<Dialog
			fullScreen={isMobile}
			open={location.pathname.includes('place-order')}
			onClose={onClose}>
			<Container>
				<CloseIconContainer onClick={onClose}>
					<FontAwesomeIcon icon={faTimes} color={colors.white} />
				</CloseIconContainer>
				<IllustrationAndText />
				<Spacer height={10} />
				<ButtonsRow>
					<Button
						inverse
						label="Encargá y retirá"
						onClick={() => navigate('/my-store')}
						width={40}
					/>
					<Spacer height={10} />
					<ButtonColumn>
						{!!store?.delivery_notes && (
							<>
								<BodyText color={colors.white} align="center" bold={isMobile}>
									{store.delivery_notes}
								</BodyText>
								<Spacer height={2} />
							</>
						)}
						{store?.delivery_enabled && (
							<Button
								inverse
								label="Delivery"
								onClick={() => navigate('/delivery')}
								width={40}
							/>
						)}
						{!store?.delivery_enabled && (
							<BodyText color={colors.white} align="center" bold={isMobile}>
								Esta sucursal no tiene envios a domicilio.
							</BodyText>
						)}
					</ButtonColumn>
				</ButtonsRow>
			</Container>
		</Dialog>
	);
};

const IllustrationAndText = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const children = (
		<>
			<Illustration src={placeOrderIllustration} />
			<Spacer width={1} />
			<Spacer height={3} />
			<Column>
				<BodyText
					color={colors.white}
					align={isMobile ? 'center' : undefined}
					bold={isMobile}>
					Tené en cuenta que los fines de semana nuestras líneas telefónicas pueden estar
					saturadas.
				</BodyText>
				<Spacer height={10} />
				<BodyText
					color={colors.white}
					align={isMobile ? 'center' : undefined}
					bold={isMobile}>
					¡Gracias por tu paciencia!
				</BodyText>
			</Column>
		</>
	);

	if (isMobile) {
		return <CenteredColumn>{children}</CenteredColumn>;
	}

	return <SpacedRow>{children}</SpacedRow>;
};

const Container = styled(Column)`
	overflow-x: hidden;
	background-color: ${colors.accentPrimary};
	padding: ${spacingPx(18)} ${spacingPx(12)};

	@media ${mediaSize.mobile} {
		height: 100%;
	}
`;

const Illustration = styled.img`
	width: ${spacingPx(45)};
	height: ${spacingPx(45)};
`;

const ButtonsRow = styled(SpacedRow)`
	align-items: flex-end;

	@media ${mediaSize.mobile} {
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
`;

const ButtonColumn = styled(Column)`
	width: ${spacingPx(52)};
`;

const CloseIconContainer = styled.div`
	font-size: 30px;
	position: absolute;
	top: ${spacingPx(2)};
	right: ${spacingPx(5)};
	cursor: pointer;

	@media ${mediaSize.mobile} {
		top: ${spacingPx(8)};
		right: ${spacingPx(8)};
	}
`;

export default PlaceOrder;
