import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface State {
	category?: string;
	keyword: string;
}

export const filtersSlice = createSlice({
	name: 'filters',
	initialState: { category: undefined, keyword: '' } as State,
	reducers: {
		setFilterKeyword: (state, action: PayloadAction<string>) => {
			state.keyword = action.payload;
		},
		resetFilterKeyword: (state, action: PayloadAction<string>) => {
			state.keyword = '';
		},
		setFilterCategory: (state, action: PayloadAction<string>) => {
			state.category = action.payload;
		},
		resetFilterCategory: state => {
			state.category = undefined;
		},
		resetAll: state => {
			state.category = undefined;
			state.keyword = '';
		},
	},
});

export default filtersSlice.reducer;
