export const colors = {
	white: '#FFFFFF',
	text: '#333333',
	lightGray: '#EAEAEA',
	gray: '#DFDFDF',
	darkGray: '#424242',
	transparent: '#FFFFFF00',

	body: '#F9F9F9',
	background: '#E5E5E5',
	accentPrimary: '#CA0612',
	accentSecondary: '#20276D',
};

const BASE_SPACING = 4;

export const spacing = (multiplier?: number) =>
	multiplier ? BASE_SPACING * multiplier : 0;
export const spacingPx = (multiplier?: number) => spacing(multiplier) + 'px';

export const mediaSize = {
	mobile: '(max-width: 899px)',
	desktop: '(min-width: 900px)',
};

export const boxShadow = {
	default: '2px 2px 10px rgba(0, 0, 0, 0.1)',
};
