import styled from 'styled-components';

export const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

export const CenteredRow = styled(Row)`
	justify-content: center;
`;

export const SpacedRow = styled(Row)`
	justify-content: space-between;
`;
