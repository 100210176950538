import styled from 'styled-components';
import { BodyText } from '../text';
import { Column } from '../layout';
import FirebaseImg from '../firebase-img';
import { spacingPx, colors, boxShadow } from '../../theme';
import { Spacer } from '..';
import viewMore from '../../assets/view-more.png';

interface ProductLikeObject {
	title: string;
	subtitle: string;
	image_url: string;
}

interface Props {
	product: ProductLikeObject;
	onClick: () => void;
}

const ProductCard = ({ product, onClick }: Props) => {
	return (
		<Card onClick={onClick}>
			<Image url={product.image_url} />
			<Footer>
				<BodyText bold>{product.title}</BodyText>
				<SubtitleText small>{product.subtitle}</SubtitleText>
				<Spacer height={2} />
				<ViewMore src={viewMore} />
			</Footer>
		</Card>
	);
};

const Card = styled(Column)`
	border-radius: ${spacingPx(2)};
	background-color: ${colors.white};
	margin-right: ${spacingPx(6)};
	box-shadow: ${boxShadow.default};
	cursor: pointer;
`;

const ViewMore = styled.img`
	width: ${spacingPx(14)};
	height: ${spacingPx(4.5)};
	align-self: flex-end;
`;

const Image = styled(FirebaseImg)`
	width: ${spacingPx(51)};
	height: ${spacingPx(40)};
	background-color: ${colors.lightGray};
	border-radius: ${spacingPx(2)} ${spacingPx(2)} 0 0;
	object-fit: cover;
	min-height: ${spacingPx(40)};
`;

const SubtitleText = styled(BodyText)``;

const Footer = styled(Column)`
	width: ${spacingPx(45)};
	min-height: ${spacingPx(16)};
	padding: ${spacingPx(2)} ${spacingPx(3)};
`;

export default ProductCard;
