import styled from 'styled-components';
import { Row } from '../layout';
import Spacer from '../spacer';
import { useMediaQuery, useTheme } from '@mui/material';
import { colors, mediaSize, spacingPx } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { animateScroll } from 'react-scroll';
import { useState } from 'react';
import _ from 'lodash';

interface Props {
	height?: number;
	children: React.ReactNode;
}

const FullWidthCarousel = ({ children, height }: Props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [scrollPosition, setScrollPosition] = useState(0);
	const [scrollWidth, setScrollWidth] = useState(100);
	const [uniqueScrollId] = useState(_.uniqueId);

	const scrollBy = (e: any, left: number) => {
		animateScroll.scrollMore(left, {
			horizontal: true,
			containerId: uniqueScrollId,
			duration: 250,
			smooth: 'linear',
		});
	};

	const scrollDistance = 500;

	return (
		<OuterContainer>
			<ArrowContainerLeft
				scrollPosition={scrollPosition}
				onClick={e => scrollBy(e, -scrollDistance)}>
				<FontAwesomeIcon icon={faChevronLeft} color={colors.darkGray} />
			</ArrowContainerLeft>
			<Container
				id={uniqueScrollId}
				height={height}
				onScroll={event => {
					const eventTarget = event.target as any;
					setScrollPosition(eventTarget.scrollLeft);
					setScrollWidth(eventTarget.scrollWidth - eventTarget.clientWidth);
				}}>
				<Spacer width={isMobile ? 6 : 12} />
				{children}
				<Spacer width={isMobile ? 6 : 12} />
			</Container>
			<ArrowContainerRight
				scrollPosition={scrollPosition}
				scrollWidth={scrollWidth}
				onClick={e => scrollBy(e, scrollDistance)}>
				<FontAwesomeIcon icon={faChevronRight} color={colors.darkGray} />
			</ArrowContainerRight>
		</OuterContainer>
	);
};

const OuterContainer = styled(Row)`
	width: calc(100% + ${spacingPx(12)});

	@media ${mediaSize.mobile} {
		width: calc(100% + ${spacingPx(6)});
	}
`;

const ArrowContainer = styled.div`
	width: ${spacingPx(12)};
	height: ${spacingPx(12)};
	margin-bottom: ${spacingPx(24)};
	position: absolute;
	z-index: 1;
	display: flex;
	align-items: center;
	background-color: ${colors.white};
	border-radius: ${spacingPx(12)};
	justify-content: center;
	cursor: pointer;

	@media ${mediaSize.mobile} {
		display: none;
	}
`;

const ArrowContainerLeft = styled(ArrowContainer)<{ scrollPosition: number }>`
	left: ${spacingPx(6)};
	opacity: ${({ scrollPosition }) => (scrollPosition === 0 ? 0 : 1)};
`;

const ArrowContainerRight = styled(ArrowContainer)<{
	scrollPosition: number;
	scrollWidth: number;
}>`
	right: ${spacingPx(6)};
	opacity: ${({ scrollPosition, scrollWidth }) =>
		scrollPosition === scrollWidth ? 0 : 1};
`;

const Container = styled(Row)<{ height?: number }>`
	position: relative;
	width: 100%;
	left: -${spacingPx(12)};
	overflow: scroll;

	align-items: flex-start;

	padding-top: ${spacingPx(1)};
	padding-bottom: ${spacingPx(1)};

	${({ height }) => (height ? `height: ${spacingPx(height)}` : ``)};

	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */

	::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}

	::-webkit-scrollbar-thumb {
		background: #000000;
		display: none;
	}

	@media ${mediaSize.mobile} {
		left: -${spacingPx(6)};
	}
`;

export default FullWidthCarousel;
