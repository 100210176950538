import React, { useState, useEffect } from 'react';
import { useLocation, useMatch, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme, Dialog } from '@mui/material';
import { useAppSelector } from '../../hooks/redux';
import styled from 'styled-components';

import { spacingPx, colors, mediaSize } from '../../theme';
import {
	Column,
	Heading1,
	Heading2,
	Heading3,
	Spacer,
	Row,
	LargeText,
	SpacedRow,
	CenteredColumn,
	FirebaseImg,
} from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faChevronLeft,
	faExclamationCircle,
	faPhoneAlt,
} from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { ProductUtils } from '../../utils';

const locationMinusProductComponents = (location: string) => {
	return location.replace(/\/products\/[\d\w]+/g, '');
};

const ProductDetails = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const location = useLocation();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const match = useMatch({
		path: `${locationMinusProductComponents(location.pathname)}/products/:id`,
		end: false,
	});

	const productId = match?.params.id;

	const currentStore = useAppSelector(state => state.currentStore);
	const product = useAppSelector(state =>
		productId ? state.products.find(p => p.id === productId) : undefined
	);

	const [open, setOpen] = useState(!!productId);

	useEffect(() => {
		setOpen(!!productId);
	}, [productId]);

	const onClose = () => {
		setOpen(false);

		const nextLocation = locationMinusProductComponents(location.pathname);

		if (nextLocation !== '') return navigate(nextLocation);

		setTimeout(() => navigate(nextLocation), 400);
	};

	const extras = product ? ProductUtils.sortedExtras(product.extra) : [];

	if (!product) return null;

	return (
		<Dialog fullScreen={isMobile} open={open} onClose={onClose}>
			<Container>
				{isMobile && (
					<ModalHeader>
						<SpacedRow>
							<BackButton onClick={onClose}>
								<FontAwesomeIcon icon={faChevronLeft} color={colors.text} />
							</BackButton>
							<CallButton />
						</SpacedRow>
						<Spacer height={8} />
						<Heading1>{currentStore?.name}</Heading1>
					</ModalHeader>
				)}
				<Image url={product.image_url} />

				<CloseIconContainer onClick={onClose}>
					<FontAwesomeIcon icon={faTimes} color={colors.text} />
				</CloseIconContainer>

				{!!product && (
					<>
						<Footer>
							<Column style={{ flex: 1 }}>
								<TitleColumn>
									<Heading2>{product.title}</Heading2>
									<Spacer height={2} />
									<Heading3Thin>{product.subtitle}</Heading3Thin>
									<Spacer height={5} />
								</TitleColumn>
								{isMobile && <Spacer height={10} />}
								{extras?.map(extra => (
									<ProductExtra productExtra={extra} key={extra[0]} />
								))}
							</Column>
							{!isMobile && <CallButton />}
						</Footer>

						{product.pre_order_only && (
							<PreOrderOnly>
								<FontAwesomeIcon
									icon={faExclamationCircle}
									color={colors.accentPrimary}
								/>
								<Spacer width={2} />
								<Heading3 color={colors.accentPrimary}>Producto por encargo</Heading3>
							</PreOrderOnly>
						)}
						<Spacer height={12} />
					</>
				)}
			</Container>
		</Dialog>
	);
};

interface TitleColumnProps {
	children?: JSX.Element | JSX.Element[];
}

const TitleColumn = ({ children }: TitleColumnProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	if (isMobile) return <CenteredColumn>{children}</CenteredColumn>;
	return <Column>{children}</Column>;
};

const CallButton = () => {
	const location = useLocation();
	const navigate = useNavigate();

	return (
		<CallColumn onClick={() => navigate(`${location.pathname}/place-order`)}>
			<FontAwesomeIcon icon={faPhoneAlt} color={colors.text} />
		</CallColumn>
	);
};

interface ProductExtraProps {
	productExtra: [string, string | string[]];
}

const ProductExtra = ({ productExtra }: ProductExtraProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const title = productExtra[0];
	const value = productExtra[1];

	if (typeof value === 'string') {
		if (isMobile) {
			return (
				<Column>
					<Heading3>{title}</Heading3>
					<LargeText bold>{value}</LargeText>
					<Spacer height={3} />
				</Column>
			);
		} else {
			return (
				<Column>
					<ProductExtraRow>
						<Heading3>{title}:</Heading3>
						<Spacer width={2} />
						<LargeText bold>{value}</LargeText>
					</ProductExtraRow>
					<Spacer height={2} />
				</Column>
			);
		}
	}

	return (
		<Column>
			<Heading3>
				{title}
				{!isMobile && ':'}
			</Heading3>

			<Column>
				{value.map(i => (
					<LargeText bold key={i}>
						• {i}
					</LargeText>
				))}
			</Column>

			<Spacer height={2} />
		</Column>
	);
};

const Container = styled(Column)`
	overflow-x: hidden;
`;

const Image = styled(FirebaseImg)`
	width: ${spacingPx(150)};
	min-height: ${spacingPx(72)};
	height: ${spacingPx(72)};

	background-color: ${colors.lightGray};
	border-radius: ${spacingPx(1)} ${spacingPx(1)} 0 0;
	object-fit: cover;

	@media ${mediaSize.mobile} {
		border-radius: 0;
		margin: 0 ${spacingPx(10)};
		width: calc(100% - ${spacingPx(20)});
	}
`;

const ModalHeader = styled(Column)`
	padding: ${spacingPx(8.5)} ${spacingPx(10)};
`;

const BackButton = styled(Row)`
	margin-left: -${spacingPx(2)};
	padding: ${spacingPx(2)};
	cursor: pointer;
`;

const CloseIconContainer = styled.div`
	font-size: 30px;
	position: absolute;
	top: ${spacingPx(2)};
	right: ${spacingPx(5)};
	cursor: pointer;

	@media ${mediaSize.mobile} {
		display: none;
	}
`;

const PreOrderOnly = styled(Row)`
	justify-content: flex-end;
	padding-right: ${spacingPx(10)};
	padding-top: ${spacingPx(10)};
`;

const CallColumn = styled(Column)`
	cursor: pointer;
	align-self: flex-start;

	@media ${mediaSize.mobile} {
		align-self: center;
		padding-top: ${spacingPx(2)};
	}
`;

const ProductExtraRow = styled(Row)`
	align-items: flex-start;
`;

const Footer = styled(SpacedRow)`
	padding: ${spacingPx(8)} ${spacingPx(10)} 0 ${spacingPx(10)};
`;

const Heading3Thin = styled(Heading3)`
	font-weight: 400;
`;

export default ProductDetails;
