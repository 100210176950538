import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	Column,
	Heading2,
	ProductCard,
	Spacer,
	FullWidthCarousel,
	CardContentLoader,
} from '../../components';
import { useAppSelector } from '../../hooks/redux';

const FeaturedProducts = () => {
	const navigate = useNavigate();

	const products = useAppSelector(state =>
		state.products
			.filter(p => p.featured)
			.sort((p1, p2) => p1.featured_index - p2.featured_index)
	);

	return (
		<Column>
			<Heading2>Productos Destacados</Heading2>
			<Spacer height={7} />
			<FullWidthCarousel>
				{products.map(p => (
					<ProductCard
						key={p.id}
						product={p}
						onClick={() => navigate(`/products/${p.id}`)}
					/>
				))}
				{products.length === 0 && (
					<CardContentLoader n={6} width={51} height={60} margin={6} />
				)}
			</FullWidthCarousel>
		</Column>
	);
};

export default FeaturedProducts;
