import { collection, getFirestore, getDocs, query, orderBy } from 'firebase/firestore';
import { Category } from '../types';
import { FirestoreUtils } from '../utils';

const db = getFirestore();

export const get = () =>
	getDocs(query(collection(db, 'categories'), orderBy('index')))
		.then(res => FirestoreUtils.objectsFromQuerySnapshot<Category>(res))
		.catch(() => [] as Category[]);
