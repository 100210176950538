import React, { useEffect } from 'react';
import styled from 'styled-components';

import {
	BodyText,
	Column,
	Heading2,
	Spacer,
	Row,
	CenteredColumn,
	CardContentLoader,
	FullWidthCarousel,
	FirebaseImg,
} from '../../components';
import { useAppSelector } from '../../hooks/redux';
import { spacingPx, colors, boxShadow } from '../../theme';
import { CategoriesApi } from '../../api';
import { categoriesSlice } from '../../store/slices';
import { useAppDispatch } from '../../hooks/redux';
import { Category } from '../../types';
import { filtersSlice } from '../../store/slices/';

const CARD_WIDTH = 37;
const IMAGE_HEIGHT = 37;
const FOOTER_HEIGHT = 14;
const CARD_HEIGHT = IMAGE_HEIGHT + FOOTER_HEIGHT;
const CARD_MARGIN = 6;

const Categories = () => {
	const dispatch = useAppDispatch();
	const categories = useAppSelector(state => state.categories);

	useEffect(() => {
		CategoriesApi.get().then(res => dispatch(categoriesSlice.actions.setCategories(res)));
	}, [dispatch]);

	return (
		<Column>
			<Heading2>Categorías</Heading2>
			<Spacer height={7} />
			<Row>
				<FullWidthCarousel>
					{categories.map(c => (
						<CategoryCard category={c} key={c.name} />
					))}
				</FullWidthCarousel>
				{categories.length === 0 && (
					<CardContentLoader
						n={5}
						width={CARD_WIDTH}
						height={CARD_HEIGHT}
						margin={CARD_MARGIN}
					/>
				)}
			</Row>
		</Column>
	);
};

const CategoryCard = ({ category }: { category: Category }) => {
	const dispatch = useAppDispatch();

	const categoryFilter = useAppSelector(state => state.filters.category);

	const selected = category.name === categoryFilter;

	const onClick = () => {
		if (selected) {
			dispatch(filtersSlice.actions.resetFilterCategory());
		} else {
			dispatch(filtersSlice.actions.setFilterCategory(category.name));
		}
	};

	return (
		<Card onClick={onClick}>
			<Image url={category.image_url} />
			<Footer selected={selected}>
				<BodyText bold color={selected ? colors.white : colors.text}>
					{category.name}
				</BodyText>
			</Footer>
		</Card>
	);
};

const Card = styled(Column)`
	border-radius: ${spacingPx(2)};
	background-color: ${colors.white};
	margin-right: ${spacingPx(CARD_MARGIN)};
	box-shadow: ${boxShadow.default};
	cursor: pointer;
`;

const Image = styled(FirebaseImg)`
	width: ${spacingPx(CARD_WIDTH)};
	height: ${spacingPx(IMAGE_HEIGHT)};
	min-height: ${spacingPx(IMAGE_HEIGHT)};
	background-color: ${colors.lightGray};
	border-radius: ${spacingPx(2)} ${spacingPx(2)} 0 0;
`;

const Footer = styled(CenteredColumn)<{ selected: boolean }>`
	justify-content: center;
	width: ${spacingPx(CARD_WIDTH)};
	height: ${spacingPx(FOOTER_HEIGHT)};
	border-radius: 0 0 ${spacingPx(2)} ${spacingPx(2)};
	background-color: ${({ selected }) =>
		selected ? colors.accentSecondary : colors.transparent};
`;

export default Categories;
