import React from 'react';
import styled from 'styled-components';
import { Row } from '../layout';
import { colors, mediaSize, spacingPx, boxShadow } from '../../theme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/pro-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { filtersSlice } from '../../store/slices';
import { faSyncAlt } from '@fortawesome/pro-regular-svg-icons';

const SearchBar = () => {
	const dispatch = useAppDispatch();
	const keyword = useAppSelector(state => state.filters.keyword);

	return (
		<Row>
			<Container>
				<Input
					placeholder="Buscar"
					value={keyword}
					onChange={e => dispatch(filtersSlice.actions.setFilterKeyword(e.target.value))}
				/>
				<FontAwesomeIcon icon={faSearch} color={colors.darkGray} />
			</Container>
			<ResetFiltersButton />
		</Row>
	);
};

const ResetFiltersButton = () => {
	const dispatch = useAppDispatch();

	const keyword = useAppSelector(state => state.filters.keyword);
	const category = useAppSelector(state => state.filters.category);

	if (!keyword && !category) {
		return null;
	}

	return (
		<ResetButtonContainer onClick={() => dispatch(filtersSlice.actions.resetAll())}>
			<FontAwesomeIcon icon={faSyncAlt} color={colors.darkGray} />
		</ResetButtonContainer>
	);
};

const Container = styled(Row)`
	background-color: ${colors.gray};
	height: ${spacingPx(4)};

	border-radius: ${spacingPx(14)};
	padding: ${spacingPx(4)} ${spacingPx(8)};
	justify-content: space-between;

	&:hover {
		background: ${colors.lightGray};
	}

	@media ${mediaSize.mobile} {
		width: calc(100% - ${spacingPx(50)});
	}
`;

const Input = styled.input`
	width: ${spacingPx(90)};
	color: ${colors.darkGray};
	background: ${colors.transparent};
	padding: 12px 12px;
	border: none;
	outline: none;

	@media ${mediaSize.mobile} {
		width: 100%;
	}
`;

const ResetButtonContainer = styled(Row)`
	margin-left: ${spacingPx(6)};
	align-items: center;
	justify-content: center;
	background-color: ${colors.white};
	height: ${spacingPx(12)};
	width: ${spacingPx(12)};
	border-radius: ${spacingPx(12)};
	font-size: ${spacingPx(7)};
	box-shadow: ${boxShadow.default};

	&:hover {
		background: ${colors.lightGray};
		cursor: pointer;
	}
`;

export default SearchBar;
