import React from 'react';
import ContentLoader from 'react-content-loader';
import { spacing } from '../../theme';

interface Props {
	n: number;
	width: number;
	height: number;
	margin: number;
}

const CardContentLoader = ({ n, width, height, margin }: Props) => {
	const screenWidth = window.innerWidth;
	let adjustedN = n;

	if (screenWidth < spacing(width + margin) * n) {
		adjustedN = screenWidth / spacing(width + margin);
	}

	const totalWidth = spacing(width + margin) * adjustedN;
	const indexes = Array.from(Array(n).keys());

	return (
		<ContentLoader
			speed={2}
			width={totalWidth}
			height={spacing(height)}
			viewBox={`0 0 ${totalWidth.toString()} ${spacing(height).toString()}`}
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb">
			{indexes.map(idx => (
				<rect
					key={idx.toString()}
					x={(spacing(width + margin) * idx).toString()}
					y="0"
					rx="8"
					ry="8"
					width={spacing(width).toString()}
					height={spacing(height).toString()}
				/>
			))}
		</ContentLoader>
	);
};

export default CardContentLoader;
