import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
	FullWidthCarousel,
	Column,
	Heading2,
	ProductCard,
	Spacer,
} from '../../components';
import { useAppSelector } from '../../hooks/redux';
import { Product } from '../../types';

const filterProducts = (products: Product[], keyword: string, category?: string) => {
	return products
		.filter(p => {
			return !category || p.category === category;
		})
		.filter(p => {
			const lowercaseTitle = p.title.toLowerCase();
			const lowercaseSubtitle = p.title.toLowerCase();
			const lowercaseKeyword = keyword.toLowerCase();

			return (
				!keyword ||
				lowercaseTitle.includes(lowercaseKeyword) ||
				lowercaseSubtitle.includes(lowercaseKeyword)
			);
		});
};

const FilteredProducts = () => {
	const navigate = useNavigate();

	const category = useAppSelector(state => state.filters.category);
	const keyword = useAppSelector(state => state.filters.keyword);

	const products = useAppSelector(state =>
		filterProducts(state.products, keyword, category)
	);

	const title = category ? category : `Resultados`;

	if (products.length === 0) {
		return <Heading2>No hubo resultados para tu busqueda</Heading2>;
	}

	return (
		<Column>
			<Heading2>{title}</Heading2>
			<Spacer height={7} />
			<FullWidthCarousel>
				{products.map(p => (
					<ProductCard
						key={p.id}
						product={p}
						onClick={() => navigate(`/products/${p.id}`)}
					/>
				))}
			</FullWidthCarousel>
		</Column>
	);
};

export default FilteredProducts;
