import React, { useEffect } from 'react';
import { Dialog } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import styled from 'styled-components';
import { Column } from '../../components';
import logoSVG from '../../assets/svg/logo.svg';
import { StoresApi } from '../../api';
import { storesSlice } from '../../store/slices';
import Stores from '../stores';

const StoreSelectionBlocker = () => {
	const dispatch = useAppDispatch();

	const currentStore = useAppSelector(state => state.currentStore);
	const stores = useAppSelector(state => state.stores);

	useEffect(() => {
		StoresApi.get().then(res => dispatch(storesSlice.actions.setStores(res)));
	}, [dispatch]);

	return (
		<Dialog fullScreen open={!currentStore} transitionDuration={{ enter: 0, exit: 300 }}>
			{stores.length === 0 && (
				<ImageContainer>
					<Image src={logoSVG} />
				</ImageContainer>
			)}
			{stores.length > 0 && <Stores isInitialStoreSelection />}
		</Dialog>
	);
};

const ImageContainer = styled(Column)`
	width: 100%;
	height: 100%;
	align-items: center;
	justify-content: center;
`;

const Image = styled.img`
	width: 60%;
	height: 60%;
	object-fit: contain;
`;

export default StoreSelectionBlocker;
