import { combineReducers, configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import {
	persistStore,
	persistReducer,
	FLUSH,
	REHYDRATE,
	PAUSE,
	PERSIST,
	PURGE,
	REGISTER,
} from 'redux-persist';
import {
	categoriesSlice,
	productsSlice,
	combosSlice,
	filtersSlice,
	storesSlice,
	currentStoreSlice,
	imageUrlsSlice,
} from './slices';

const persistConfig = {
	key: 'root',
	version: 1,
	storage,
	blacklist: ['filters', 'imageUrls'],
};

const rootReducer = combineReducers({
	products: productsSlice.reducer,
	categories: categoriesSlice.reducer,
	combos: combosSlice.reducer,
	filters: filtersSlice.reducer,
	stores: storesSlice.reducer,
	currentStore: currentStoreSlice.reducer,
	imageUrls: imageUrlsSlice.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware({
			serializableCheck: {
				ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
			},
		}),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
