import { useMediaQuery, useTheme } from '@mui/material';
import { Heading1 } from '../text';
import { Row, Column } from '../layout';
import SearchBar from '../search-bar';
import Spacer from '../spacer';

import styled from 'styled-components';

import { spacingPx } from '../../theme';
import { useAppSelector } from '../../hooks/redux';

interface Props {
	showSearchBar?: Boolean;
}

const CurrentStoreTitle = ({ showSearchBar }: Props) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const currentStore = useAppSelector(state => state.currentStore);

	if (!currentStore) {
		return null;
	}

	if (isMobile) {
		return (
			<Column>
				<MobileHeading1>{currentStore.name}</MobileHeading1>
				{showSearchBar && (
					<>
						<Spacer height={5} />
						<SearchBar />
					</>
				)}
			</Column>
		);
	}

	return (
		<TitleRow>
			<Heading1>{currentStore.name}</Heading1>
			{showSearchBar && (
				<>
					<Spacer width={10} />
					<SearchBar />
				</>
			)}
		</TitleRow>
	);
};

const TitleRow = styled(Row)`
	margin-top: -${spacingPx(1.75)};
`;

const MobileHeading1 = styled(Heading1)`
	max-width: calc(100% - ${spacingPx(40)});
`;

export default CurrentStoreTitle;
