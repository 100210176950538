import styled from 'styled-components';
import { colors } from '../../theme';

interface Props {
	bold?: boolean;
	color?: string;
	light?: boolean;
	small?: boolean;
	align?: 'left' | 'right' | 'center';
	clickable?: boolean;
}

const fonts = {
	primary: 'Open Sans',
	size: {
		small: 12,
		normal: 14,
	},
};
export const BodyText = styled.span<Props>`
	font-family: ${fonts.primary};
	font-size: ${({ small }) => (small ? fonts.size.small : fonts.size.normal)}px;
	font-weight: ${({ bold }) => (bold ? '600' : '300')};
	line-height: 1.45;
	color: ${props => {
		if (props.color) return props.color;
		if (props.light) return colors.darkGray;
		return colors.text;
	}};
	text-align: ${({ align }) => align};
	hover {
		cursor: ${({ clickable }) => (clickable ? 'pointer !important' : 'none')};
	}
`;

BodyText.defaultProps = {
	align: 'left',
};

export const Heading1 = styled(BodyText)`
	font-size: 30px;
	font-weight: 700;
	line-height: 1.14;
`;

export const Heading2 = styled(BodyText)`
	font-size: 25px;
	font-weight: 700;
	line-height: 28px;
`;

export const Heading3 = styled(BodyText)`
	font-size: 21px;
	font-weight: 700;
	line-height: 25px;
`;

export const Heading4 = styled(BodyText)`
	font-size: 19px;
	font-weight: 700;
	line-height: 23px;
`;

export const Heading5 = styled(BodyText)`
	font-size: 17px;
	font-weight: 700;
	line-height: 21px;
`;

export const Heading6 = styled(BodyText)`
	font-size: 16px;
	font-weight: 600;
	line-height: 18px;
`;

export const LargeText = styled(BodyText)`
	font-size: 17px;
	font-weight: 400;
	line-height: 26px;
`;
