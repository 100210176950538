import styled from 'styled-components';
import { spacingPx, colors, boxShadow } from '../../theme';
import { Row } from '../layout';
import { BodyText } from '../text';

interface Props {
	label: string;
	width?: number;
	inverse?: boolean;
	fullWidth?: boolean;
	onClick: () => void;
}

const Button = ({ label, width, inverse, fullWidth, onClick }: Props) => {
	const children = (
		<Wrapper width={width} inverse={inverse} onClick={onClick}>
			<BodyText bold color={inverse ? colors.accentSecondary : colors.white}>
				{label}
			</BodyText>
		</Wrapper>
	);

	if (fullWidth) {
		return children;
	}

	return <Row>{children}</Row>;
};

const Wrapper = styled(Row)<{ width?: number; inverse?: boolean }>`
	height: ${spacingPx(12)};
	${({ width }) => (width ? `width: ${spacingPx(width)}` : ``)};
	background-color: ${({ inverse }) => (inverse ? colors.white : colors.accentPrimary)};
	padding: 0 ${spacingPx(6)};
	border-radius: ${spacingPx(2)};
	cursor: pointer;
	justify-content: center;
	text-decoration: none;

	${({ inverse }) => (inverse ? `box-shadow: ${boxShadow.default}` : '')};

	&:hover {
		opacity: 0.6;
	}
`;

export default Button;
