import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type State = { [key: string]: string };

interface Payload {
	url: string;
	resolvedUrl: string;
}

export const imageUrlsSlice = createSlice({
	name: 'categories',
	initialState: {} as State,
	reducers: {
		setResolvedUrl: (state, action: PayloadAction<Payload>) => {
			state[action.payload.url] = action.payload.resolvedUrl;
		},
	},
});

export default imageUrlsSlice.reducer;
