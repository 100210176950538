import styled from 'styled-components';
import { spacingPx, mediaSize } from '../../theme';

export const ScreenWrapper = styled.div`
	width: calc(100vw - ${spacingPx(12)});
	min-height: calc(100vh - ${spacingPx(95)});
	display: flex;
	flex-direction: column;
	padding: ${spacingPx(12)} 0 ${spacingPx(22)} ${spacingPx(12)};
	justify-content: flex-start;
	overflow-x: hidden;

	@media ${mediaSize.mobile} {
		width: calc(100vw - ${spacingPx(6)});
		padding-left: ${spacingPx(6)};
	}
`;
