import { DOMAttributes } from 'react';
import styled from 'styled-components';

export const Column = styled.div<DOMAttributes<Element>>`
	display: flex;
	flex-direction: column;
`;

export const CenteredColumn = styled(Column)`
	align-items: center;
`;

export const SpacedColumn = styled(Column)`
	justify-content: space-between;
`;
