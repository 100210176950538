import React, { useState, useEffect } from 'react';
import { useMatch, useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme, Dialog } from '@mui/material';
import { useAppSelector } from '../../hooks/redux';
import styled from 'styled-components';

import { spacingPx, colors, mediaSize } from '../../theme';
import {
	CenteredColumn,
	Column,
	Heading1,
	Heading2,
	Heading3,
	Spacer,
	Button,
	SpacedRow,
	Row,
	CallButton,
	FirebaseImg,
} from '../../components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';

const ComboDetails = () => {
	const theme = useTheme();
	const navigate = useNavigate();
	const match = useMatch('/combos/:id');
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const comboId = match?.params.id;

	const currentStore = useAppSelector(state => state.currentStore);
	const combo = useAppSelector(state =>
		comboId ? state.combos.find(c => c.id === comboId) : undefined
	);

	const [open, setOpen] = useState(!!comboId);

	useEffect(() => {
		setOpen(!!comboId);
	}, [comboId]);

	const onClose = () => {
		setOpen(false);
		setTimeout(() => navigate('/products'), 400);
	};

	if (!combo) return null;

	return (
		<Dialog fullScreen={isMobile} open={open} onClose={onClose}>
			<Container>
				{isMobile && (
					<ModalHeader>
						<SpacedRow>
							<BackButton onClick={onClose}>
								<FontAwesomeIcon icon={faChevronLeft} color={colors.text} />
							</BackButton>
							<CallButton />
						</SpacedRow>
						<Spacer height={8} />
						<Heading1>{currentStore?.name}</Heading1>
					</ModalHeader>
				)}
				<Image url={combo.image_url} />

				<CloseIconContainer onClick={onClose}>
					<FontAwesomeIcon icon={faTimes} color={colors.text} />
				</CloseIconContainer>

				{!!combo && (
					<>
						<Footer>
							<Column style={{ flex: 1 }}>
								<TitleColumn>
									<Heading2>{combo.title}</Heading2>
									<Spacer height={2} />
									<Heading3Thin>{combo.subtitle}</Heading3Thin>
									<Spacer height={5} />
								</TitleColumn>
								{isMobile && <Spacer height={10} />}
								{combo.product_ids.map(pid => (
									<ProductButton productId={pid} comboId={combo.id} key={pid} />
								))}
							</Column>
							{!isMobile && <CallButton />}
						</Footer>
						<Spacer height={8} />
					</>
				)}
			</Container>
		</Dialog>
	);
};

interface TitleColumnProps {
	children?: JSX.Element | JSX.Element[];
}

const TitleColumn = ({ children }: TitleColumnProps) => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	if (isMobile) return <CenteredColumn>{children}</CenteredColumn>;
	return <Column>{children}</Column>;
};

interface ProductButtonProps {
	productId: string;
	comboId: string;
}

const ProductButton = ({ productId, comboId }: ProductButtonProps) => {
	const navigate = useNavigate();
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const product = useAppSelector(state => state.products.find(p => p.id === productId));

	if (!product) return null;

	return (
		<>
			<Button
				inverse
				width={isMobile ? undefined : 68}
				fullWidth={isMobile}
				label={product.title}
				onClick={() => navigate(`${comboId}/products/${productId}`)}
			/>
			<Spacer height={5} />
		</>
	);
};

const Container = styled(Column)`
	overflow-x: hidden;
`;

const Image = styled(FirebaseImg)`
	width: ${spacingPx(150)};
	height: ${spacingPx(72)};
	min-height: ${spacingPx(72)};
	background-color: ${colors.lightGray};
	border-radius: ${spacingPx(1)} ${spacingPx(1)} 0 0;
	object-fit: cover;

	@media ${mediaSize.mobile} {
		border-radius: 0;
		margin: 0 ${spacingPx(10)};
		width: calc(100% - ${spacingPx(20)});
	}
`;

const ModalHeader = styled(Column)`
	padding: ${spacingPx(8.5)} ${spacingPx(10)};
`;

const BackButton = styled(Row)`
	margin-left: -${spacingPx(2)};
	padding: ${spacingPx(2)};
	cursor: pointer;
`;

const CloseIconContainer = styled.div`
	font-size: 30px;
	position: absolute;
	top: ${spacingPx(2)};
	right: ${spacingPx(5)};
	cursor: pointer;

	@media ${mediaSize.mobile} {
		display: none;
	}
`;

const Footer = styled(SpacedRow)`
	padding: ${spacingPx(8)} ${spacingPx(10)} 0 ${spacingPx(10)};
`;

const Heading3Thin = styled(Heading3)`
	font-weight: 400;
`;

export default ComboDetails;
