import { DocumentSnapshot, QuerySnapshot } from 'firebase/firestore';

export const objectsFromQuerySnapshot = <T extends unknown>(
	querySnapshot: QuerySnapshot
): T[] => {
	const objects: T[] = [];

	querySnapshot.forEach(doc => {
		const data = doc.data() as T;
		if (!data) return;
		objects.push(data);
	});

	return objects;
};

export const objectFromDocumentSnapshot = <T extends unknown>(
	doc: DocumentSnapshot
): T | undefined => {
	if (!doc.exists) return undefined;
	return doc.data() as T;
};
