import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/pro-solid-svg-icons';
import { spacingPx, colors, mediaSize } from '../../theme';
import styled from 'styled-components';
import { Column } from '../layout';

const CallButton = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const to =
		location.pathname === '/' ? 'place-order' : `${location.pathname}/place-order`;

	return (
		<CallColumn onClick={() => navigate(to)}>
			<FontAwesomeIcon icon={faPhoneAlt} color={colors.text} />
		</CallColumn>
	);
};

const CallColumn = styled(Column)`
	cursor: pointer;
	align-self: flex-start;

	@media ${mediaSize.mobile} {
		align-self: center;
		padding-top: ${spacingPx(2)};
	}
`;

export default CallButton;
