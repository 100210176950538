import { useEffect } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import _ from 'lodash';
import {
	ScreenWrapper,
	Heading2,
	Spacer,
	Row,
	Column,
	LargeText,
	Heading3,
	StoreLocationMap,
	SpacedRow,
	Heading6,
	Button,
	CurrentStoreTitle,
} from '../../components';

import { useAppSelector } from '../../hooks/redux';
import styled from 'styled-components';
import { colors, spacingPx, mediaSize } from '../../theme';

import card1 from '../../assets/card-1.png';
import card2 from '../../assets/card-2.png';
import card3 from '../../assets/card-3.png';
import card4 from '../../assets/card-4.png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';

const MyStore = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const currentStore = useAppSelector(state => state.currentStore);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	if (!currentStore) {
		return null;
	}

	const acceptsPaymentCards = !!currentStore.payment_methods_pick_up.find(pm =>
		pm.includes('arjeta')
	);

	return (
		<ScreenWrapper>
			<CurrentStoreTitle />
			<Spacer height={10} />
			<Heading2>Información</Heading2>
			<Sections>
				<SectionColumn>
					<StoreLocationMap store={currentStore} showDeliveryAreas={false} />
					<Spacer height={4} />
					<LargeText>{currentStore.address}</LargeText>
					<LargeText>Teléfono: {currentStore.phone_numbers.join(', ')}</LargeText>
					{!!currentStore.emails && (
						<LargeText>Email: {currentStore.emails?.join(', ')}</LargeText>
					)}
				</SectionColumn>
				<Spacer width={6} />
				<SectionColumn>
					<Heading3>Medios de pago</Heading3>
					<Spacer height={2} />
					{currentStore.payment_methods_pick_up.map(pm => (
						<LargeText key={pm}>{pm}</LargeText>
					))}
					{acceptsPaymentCards && (
						<>
							<Spacer height={4} />
							<SpacedRow>
								<CardImage src={card1} />
								<CardImage src={card2} />
								<CardImage src={card3} />
								<CardImage src={card4} />
							</SpacedRow>
						</>
					)}
					{!currentStore.delivery_enabled && (
						<>
							<Spacer height={4} />
							<Row>
								<FontAwesomeIcon
									icon={faExclamationCircle}
									color={colors.accentPrimary}
								/>
								<Spacer width={2} />
								<Heading6 color={colors.accentPrimary}>Sucursal sin delivery</Heading6>
							</Row>
						</>
					)}
					<Spacer height={4} />
					{isMobile && (
						<ButtonLink href={`tel:${currentStore.phone_numbers[0]}`}>
							<Button
								label={`Encargá y retirá`}
								onClick={() => {}}
								width={isMobile ? undefined : 70}
								fullWidth={isMobile}
							/>
						</ButtonLink>
					)}
					{!isMobile && (
						<>
							<Heading3>Encargá y retirá</Heading3>
							<Spacer height={2} />
							<LargeText>Teléfono: {_.first(currentStore.phone_numbers)}</LargeText>
						</>
					)}
				</SectionColumn>
				<Spacer width={6} />
				<SectionColumn>
					<Heading3>Horarios</Heading3>
					<Spacer height={2} />
					{currentStore.store_hours.map(h => (
						<LargeText key={h}>{h}</LargeText>
					))}
				</SectionColumn>
			</Sections>
		</ScreenWrapper>
	);
};

const SectionColumn = styled(Column)`
	height: 100%;
	margin-top: ${spacingPx(12)};
	min-width: ${spacingPx(82)};

	@media ${mediaSize.mobile} {
		min-width: 0;
		width: calc(100vw - ${spacingPx(12)});
	}
`;

const Sections = styled(Row)`
	align-items: flex-start;
	flex-wrap: wrap;

	@media ${mediaSize.mobile} {
		flex-direction: column;
	}
`;

const CardImage = styled.img`
	width: ${spacingPx(17)};
	height: ${spacingPx(12)};
`;

const ButtonLink = styled.a`
	text-decoration: none;
`;

export default MyStore;
