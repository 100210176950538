import {
	doc,
	collection,
	setDoc,
	deleteDoc,
	getFirestore,
	getDocs,
	query,
	orderBy,
} from 'firebase/firestore';
import { Combo } from '../types';
import { FirestoreUtils } from '../utils';

const db = getFirestore();

export const get = () =>
	getDocs(query(collection(db, 'combos'), orderBy('index')))
		.then(res => FirestoreUtils.objectsFromQuerySnapshot<Combo>(res))
		.catch(() => [] as Combo[]);

export const importFromArray = async (combos: Omit<Combo, 'id'>[]) => {
	for (const combo of combos) {
		const ref = doc(collection(db, 'combos'));
		await setDoc(ref, { ...combo, id: ref.id });
	}
};

export const remove = async (id: string) => {
	const ref = doc(collection(db, 'combos'), id);
	return deleteDoc(ref);
};

export const removeAll = async () => {
	const combos = await get();
	for (const combo of combos) {
		await remove(combo.id);
	}
};
