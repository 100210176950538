import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { AppDispatch } from '../store';
import { imageUrlsSlice } from '../store/slices';

export const getDownloadURLAction = async (dispatch: AppDispatch, url?: string) => {
	if (!url) return;

	const storage = getStorage();
	getDownloadURL(ref(storage, url))
		.then(resolvedUrl =>
			dispatch(imageUrlsSlice.actions.setResolvedUrl({ url, resolvedUrl }))
		)
		.catch(() => {});
};
