import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CombosApi } from '../../api';
import {
	Column,
	Heading2,
	CardContentLoader,
	Spacer,
	FullWidthCarousel,
	ProductCard,
} from '../../components';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { combosSlice } from '../../store/slices';

const FeaturedCombos = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const combos = useAppSelector(state => state.combos);

	useEffect(() => {
		CombosApi.get().then(res => dispatch(combosSlice.actions.setCombos(res)));
	}, [dispatch]);

	return (
		<Column>
			<Heading2>Combinaciones Destacadas</Heading2>
			<Spacer height={7} />
			<FullWidthCarousel>
				{combos.map(c => (
					<ProductCard
						key={c.id}
						product={c}
						onClick={() => navigate(`/combos/${c.id}`)}
					/>
				))}
				{combos.length === 0 && (
					<CardContentLoader n={6} width={51} height={60} margin={6} />
				)}
			</FullWidthCarousel>
		</Column>
	);
};

export default FeaturedCombos;
