import { useEffect } from 'react';
import { ImageUrlsApi } from '../../api';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

interface Props {
	url?: string;
	className?: string;
}

export const FirebaseImg = ({ url, className }: Props) => {
	const dispatch = useAppDispatch();

	const imageUrl = useAppSelector(state => {
		if (!url) return undefined;
		return state.imageUrls[url];
	});

	useEffect(() => {
		if (imageUrl) return;
		if (!url) return;

		ImageUrlsApi.getDownloadURLAction(dispatch, url);
	}, [url, imageUrl, dispatch]);

	if (!imageUrl) return <div className={className} />;
	return <img className={className} src={imageUrl} alt="firebase resource" />;
};

export default FirebaseImg;
