import { useState } from 'react';
import styled from 'styled-components';
import { colors, spacingPx, mediaSize } from '../../theme';
import { useMediaQuery, useTheme, Dialog } from '@mui/material';
import { Store } from '../../types';
import Map from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import { Column } from '../layout';

interface Props {
	store: Store;
	showDeliveryAreas: boolean;
}

const StoreLocationMap = ({ store, showDeliveryAreas }: Props) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const [modalOpen, setModalOpen] = useState(false);

	const handleApiLoaded = (map: any, maps: any) => {
		const marker = new maps.Marker({
			position: store.coordinates,
			map,
			shape: 'MarkerShapeCircle',
		});
		marker.setMap(map);

		if (showDeliveryAreas) {
			const deliveryPolygon = new maps.Polygon({
				paths: store.delivery_coordinates,
				strokeColor: '#FF0000',
				strokeOpacity: 0.8,
				strokeWeight: 2,
				fillColor: '#FF0000',
				fillOpacity: 0.35,
			});

			deliveryPolygon.setMap(map);
		}
	};

	return (
		<MapWrapper>
			<Map
				bootstrapURLKeys={{ key: 'AIzaSyDofFkrsz5Du3SeQJrznESv7Mufauc0CoI' }}
				defaultCenter={store.coordinates}
				defaultZoom={showDeliveryAreas ? 13 : 15.5}
				options={createMapOptions}
				yesIWantToUseGoogleMapApiInternals
				onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
				onClick={() => setModalOpen(true)}
			/>

			<Dialog
				fullScreen={fullScreen}
				open={modalOpen}
				fullWidth
				maxWidth="md"
				onClose={() => setModalOpen(false)}>
				<ModalMapWrapper>
					<CloseIconContainer onClick={() => setModalOpen(false)}>
						<FontAwesomeIcon icon={faTimes} color={colors.text} />
					</CloseIconContainer>
					<Map
						bootstrapURLKeys={{ key: 'AIzaSyDofFkrsz5Du3SeQJrznESv7Mufauc0CoI' }}
						defaultCenter={store.coordinates}
						defaultZoom={showDeliveryAreas ? 13 : 15.5}
						options={createModalMapOptions}
						yesIWantToUseGoogleMapApiInternals
						onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
						onClick={() => setModalOpen(true)}
					/>
				</ModalMapWrapper>
			</Dialog>
		</MapWrapper>
	);
};

const MapWrapper = styled.div`
	width: ${spacingPx(108)};
	height: ${spacingPx(66)};
	cursor: pointer;

	@media ${mediaSize.mobile} {
		width: 100%;
	}
`;

const ModalMapWrapper = styled(Column)`
	width: 100%;
	height: ${spacingPx(112)};
	align-items: flex-end;

	@media ${mediaSize.mobile} {
		height: 100%;
	}
`;

const CloseIconContainer = styled.div`
	font-size: ${spacingPx(8)};
	cursor: pointer;
	height: ${spacingPx(8)};
	margin: ${spacingPx(2)} ${spacingPx(4)} -${spacingPx(10)} 0;
	z-index: 1;
`;

const mapStyles = [
	{
		stylers: [{ saturation: 0 }, { gamma: 1 }, { lightness: 10 }, { visibility: 'on' }],
	},
	{
		featureType: 'poi',
		elementType: 'labels.text',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
	{
		featureType: 'poi.business',
		stylers: [
			{
				visibility: 'off',
			},
		],
	},
];

const createMapOptions = () => {
	return {
		panControl: false,
		mapTypeControl: false,
		scrollwheel: false,
		disableDefaultUI: true,
		disableDoubleClickZoom: true,
		draggable: false,
		styles: mapStyles,
	};
};

const createModalMapOptions = () => {
	return {
		mapTypeControl: false,
		fullscreenControl: false,
		styles: mapStyles,
	};
};

export default StoreLocationMap;
