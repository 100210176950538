import React, { useEffect } from 'react';
import { ScreenWrapper, Spacer, CurrentStoreTitle } from '../../components';

import { ProductsApi } from '../../api';
import { productsSlice } from '../../store/slices';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';

import ProductDetails from '../product-details';
import ComboDetails from '../combo-details';
import PlaceOrder from '../place-order';

import Categories from './categories';
import FeaturedProducts from './featured-products';
import FeaturedCombos from './featured-combos';
import FilteredProducts from './filtered-products';

const Products = () => {
	const dispatch = useAppDispatch();

	const currentStore = useAppSelector(state => state.currentStore);
	const categoryFilter = useAppSelector(state => state.filters.category);
	const keywordFilter = useAppSelector(state => state.filters.keyword);

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	useEffect(() => {
		ProductsApi.get().then(res => dispatch(productsSlice.actions.setProducts(res)));
	}, [dispatch]);

	if (!currentStore) {
		return null;
	}

	const isFiltering = categoryFilter || keywordFilter;

	return (
		<ScreenWrapper>
			<CurrentStoreTitle showSearchBar />

			<Spacer height={12} />

			<Categories />
			<Spacer height={12} />

			{!isFiltering && (
				<>
					<FeaturedProducts />
					<Spacer height={12} />
					<FeaturedCombos />
				</>
			)}

			{!!isFiltering && <FilteredProducts />}

			<ProductDetails />
			<ComboDetails />
			<PlaceOrder />
		</ScreenWrapper>
	);
};

export default Products;
