import { colors, spacingPx } from '../../theme';
import styled from 'styled-components';

interface Props {
	width?: number;
	height?: number;
}

const Spacer = styled.div`
	flex-shrink: 0;
	height: ${({ height }: Props) => (height ? spacingPx(height) : 'auto')};
	width: ${({ width }: Props) => (width ? spacingPx(width) : 'auto')};
	background-color: ${colors.transparent};
`;

export default Spacer;
