import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Category } from '../../types';

export const categoriesSlice = createSlice({
	name: 'categories',
	initialState: [] as Category[],
	reducers: {
		setCategories: (state, action: PayloadAction<Category[]>) => action.payload,
	},
});

export default categoriesSlice.reducer;
