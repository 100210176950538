import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Combo } from '../../types';

export const combosSlice = createSlice({
	name: 'combos',
	initialState: [] as Combo[],
	reducers: {
		setCombos: (state, action: PayloadAction<Combo[]>) => action.payload,
	},
});

export default combosSlice.reducer;
