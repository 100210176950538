import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Product } from '../../types';

export const productsSlice = createSlice({
	name: 'products',
	initialState: [] as Product[],
	reducers: {
		setProducts: (state, action: PayloadAction<Product[]>) => action.payload,
	},
});

export default productsSlice.reducer;
