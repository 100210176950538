import {
	collection,
	doc,
	getFirestore,
	getDocs,
	query,
	orderBy,
	setDoc,
} from 'firebase/firestore';
import { Product } from '../types';
import { FirestoreUtils } from '../utils';

const db = getFirestore();

export const get = () =>
	getDocs(query(collection(db, 'products'), orderBy('index')))
		.then(res => FirestoreUtils.objectsFromQuerySnapshot<Product>(res))
		.catch(() => [] as Product[]);

export const importFromArray = async (products: Omit<Product, 'id'>[]) => {
	for (const product of products) {
		const ref = doc(collection(db, 'products'));
		await setDoc(ref, { ...product, id: ref.id });
	}
};
