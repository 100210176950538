import { useAppSelector } from '../../hooks/redux';
import { spacingPx, mediaSize } from '../../theme';

import { SpacedRow } from '../layout';

import socialIg from '../../assets/social-ig.png';
import socialFb from '../../assets/social-fb.png';
import socialG from '../../assets/social-g.png';
import socialMaps from '../../assets/social-maps.png';
import styled from 'styled-components';

const SocialIcons = () => {
	const currentStore = useAppSelector(state => state.currentStore);
	return (
		<SocialRow>
			<a
				href="https://www.instagram.com/santainespastas/"
				target="_blank"
				rel="noreferrer">
				<SocialLogo src={socialIg} />
			</a>

			<a href="https://www.facebook.com/santainespastas" target="_blank" rel="noreferrer">
				<SocialLogo src={socialFb} />
			</a>

			<a href="https://bit.ly/33BGvbB" target="_blank" rel="noreferrer">
				<SocialLogo src={socialG} />
			</a>

			<a href={currentStore?.google_maps_url} target="_blank" rel="noreferrer">
				<SocialLogo src={socialMaps} />
			</a>
		</SocialRow>
	);
};

const SocialRow = styled(SpacedRow)`
	font-size: ${spacingPx(6)};

	@media ${mediaSize.desktop} {
		width: ${spacingPx(60)};
	}

	@media ${mediaSize.mobile} {
		flex: 1;
		font-size: ${spacingPx(7)};
		justify-content: space-between;
		padding: 0 ${spacingPx(4)};
	}
`;

const SocialLogo = styled.img`
	height: ${spacingPx(6)};
	width: ${spacingPx(6)};
	object-fit: contain;
	cursor: pointer;

	@media ${mediaSize.mobile} {
		height: ${spacingPx(7)};
		width: ${spacingPx(7)};
	}
`;

export default SocialIcons;
