import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit';
import { Store } from '../../types';

const setStores = createAction<Store[]>('stores/setStores');

type State = Store | null;

export const currentStoreSlice = createSlice({
	name: 'currentStore',
	initialState: null as State,
	reducers: {
		setCurrentStore: (_state, action: PayloadAction<Store>) => action.payload,
		resetCurrentStore: () => null,
	},
	extraReducers: builder => {
		builder.addCase(setStores, (state, action) => {
			if (!state) return state;
			const foundCurrentStore = action.payload.find(s => s.id === state.id);
			return foundCurrentStore ?? state;
		});
	},
});

export default currentStoreSlice.reducer;
