import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Store } from '../../types';

export const storesSlice = createSlice({
	name: 'stores',
	initialState: [] as Store[],
	reducers: {
		setStores: (state, action: PayloadAction<Store[]>) => action.payload,
	},
});

export default storesSlice.reducer;
