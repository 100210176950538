import React, { useEffect } from 'react';

import './App.css';
import './config/firebase';

import { BrowserRouter, Routes, Route } from 'react-router-dom';

import TopBar from './screens/top-bar';
import BottomBar from './screens/bottom-bar';
import Products from './screens/products';
import Stores from './screens/stores';
import MyStore from './screens/my-store';
import Delivery from './screens/delivery';
import StoreSelectionBlocker from './screens/store-selection-blocker';
import { useAppDispatch } from './hooks/redux';
import { StoresApi } from './api';
import { storesSlice } from './store/slices';
import PlaceOrder from './screens/place-order';

const App = () => {
	const dispatch = useAppDispatch();

	useEffect(() => {
		StoresApi.get().then(res => dispatch(storesSlice.actions.setStores(res)));
	}, [dispatch]);

	return (
		<div className="App">
			<BrowserRouter>
				<TopBar />
				<Routes>
					<Route path="/products/*" element={<Products />} />
					<Route path="/combos/*" element={<Products />} />

					<Route path="/my-store/*" element={<MyStore />} />

					<Route path="/delivery" element={<Delivery />} />

					<Route path="/stores" element={<Stores />} />

					<Route path="/*" element={<Products />} />
				</Routes>
				<PlaceOrder />
			</BrowserRouter>
			<StoreSelectionBlocker />
			<BottomBar />
		</div>
	);
};

export default App;
