import _ from 'lodash';
import { ProductExtras } from '../types';

const extrasOrder = ['Cocción', 'Porción', 'Precio'];

export const sortedExtras = (extras: ProductExtras) => {
	return _.toPairs(extras).sort((a, b) => {
		return extrasOrder.indexOf(a[0]) - extrasOrder.indexOf(b[0]);
	});
};
