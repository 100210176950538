import {
	collection,
	getFirestore,
	getDocs,
	query,
	orderBy,
	updateDoc,
	doc,
	getDoc,
} from 'firebase/firestore';
import { Store } from '../types';
import { FirestoreUtils } from '../utils';

const db = getFirestore();

export const get = () =>
	getDocs(query(collection(db, 'stores'), orderBy('index')))
		.then(res => FirestoreUtils.objectsFromQuerySnapshot<Store>(res))
		.catch(() => [] as Store[]);

export const getById = (id: string) =>
	getDoc(doc(db, 'stores', id))
		.then(res => FirestoreUtils.objectFromDocumentSnapshot<Store>(res))
		.catch(() => undefined);

export const update = (id: string, payload: Partial<Omit<Store, 'id'>>) =>
	updateDoc(doc(db, 'stores', id), payload).then(() => getById(id));
